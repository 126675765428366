<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created(){
    console.log('app launch');
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Microsoft YaHei";
}
</style>
