import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
// import Cookies from 'js-cookie'

const state = {
  token: getToken(),
  wechatOpenId: '', // 微信openid
  wxInfo: {}, // 微信用户信息
  userInfo: {}, // 员工信息
  appraisalList: [], // 问卷列表
  answerList: [], // 回答列表
  title: undefined, // 页面标题,
  month: undefined, // 问卷所属月份
  reSubmitRecordMonth: undefined, // 驳回重提交的问卷所属月分
  reSubmitRecordId: undefined, // 驳回重提交的问卷记录id
  name: '',
  sex: 0,
  level: 0,
  avatar: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_OPENID: (state, openid) => {
    state.wechatOpenId = openid
  },
  SET_WXINFO: (state, wxInfo) => {
    state.wxInfo = wxInfo
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_APPRAISALLIST: (state, appraisalList) => {
    state.appraisalList = appraisalList
  },
  SET_ANSWERLIST: (state, answerList) => {
    state.answerList = answerList
  },
  SET_TITLE: (state, title) => {
    state.title = title + '月考核问卷'
  },
  SET_MONTH: (state, month) => {
    state.month = month
  },
  SET_RESUBMITRECORDMONTH: (state, month) => {
    state.reSubmitRecordMonth = month
  },
  SET_RESUBMITRECORDID: (state, id) => {
    state.reSubmitRecordId = id
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, introduction } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
