import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

const routes = [
  {
    name: '/',
    meta: { title: '' },
    component: () => import('./view/index'),
  },
  {
    name: 'user',
    component: () => import('./view/user'),
  },
  {
    name: 'step',
    component: () => import('./view/step'),
  },
  {
    name: 'supplement',
    component: () => import('./view/supplement/supplement'),
  },
  {
    name: 'reSubmit',
    component: () => import('./view/re-submit/reSubmit'),
  },
  {
    //问卷提交成功后的
    name: 'stepComplete',
    path: '/stepComplete/*',
    component: () => import('./view/step-complete/index'),
  },
  // 结果通知页
  {
    name: 'result',
    path: '/result',
    component: () => import('./view/result/index')
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  if (to.path != '/') {
    window.document.title = store.getters.title == undefined ? '' : store.getters.title
  }
  next();
});

// router.afterEach(() => {
//   setTimeout(() => {
//     window.document.title = store.getters.title == undefined ? '' : store.getters.title
//   }, 100)
// });

export {
  router
};
