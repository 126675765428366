const getters = {
  wechatOpenId: state => state.user.wechatOpenId,
  wxInfo: state => state.user.wxInfo,
  userInfo: state => state.user.userInfo,
  appraisalList: state => state.user.appraisalList,
  answerList: state => state.user.answerList,
  title: state => state.user.title,
  month: state => state.user.month,
  reSubmitRecordMonth: state => state.user.reSubmitRecordMonth,
  reSubmitRecordId: state => state.user.reSubmitRecordId,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs
}
export default getters
