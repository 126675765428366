import axios from 'axios';
import store from '@/store';
// import router from '@/router'

// axios 配置
const service = axios.create({
	// baseURL: '/api', // url = base url + request url
	baseURL: 'https://oa-api.hbzgyc.com/', //正式地址  url = base url + request url
	// baseURL: 'http://192.168.1.177:8089/', //李根电脑地址。
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 5000 // request timeout
});

// http request 拦截器
service.interceptors.request.use(
	(config) => {
		if (store.state.token) {
			config.headers.Authorization = `token ${store.state.token}`;
		}
		// console.log(config);
		return config;
	},
	(err) => {
		return Promise.reject(err);
	}
);

// http response 拦截器
service.interceptors.response.use(
	(response) => {
		return response;

		// const res = response.data
		// if (res.code !== 200 && res.code != 9001) {
		//   this.$dialog.alert({
		//     message: res.msg || 'Error',
		//   })
		//   return

		//   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
		//   // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
		//   //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
		//   //     confirmButtonText: 'Re-Login',
		//   //     cancelButtonText: 'Cancel',
		//   //     type: 'warning'
		//   //   }).then(() => {
		//   //     store.dispatch('user/resetToken').then(() => {
		//   //       location.reload()
		//   //     })
		//   //   })
		//   // }
		//   // return Promise.reject(new Error(res.msg || 'Error'))
		// } else {
		//   return res
		// }
	},
	(error) => {
		if (error.response) {
			// switch (error.response.status) {
			//   case 401:
			//     // 401 清除token信息并跳转到登录页面
			//     // store.commit(types.LOGOUT)
			//     // 只有在当前路由不是登录页面才跳转
			//     router.currentRoute.path !== 'login' &&
			//       router.replace({
			//         path: 'login',
			//         query: { redirect: router.currentRoute.path },
			//       })
			// }
		}
		// console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
		return Promise.reject(error.response.data);
	}
);

export default service;
