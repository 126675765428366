import request from '@/config/http'

// 获取微信绑定信息
export function getBindInfo(data) {
  return request({
    url: '/weixin-mp/oa/bind-info',
    method: 'get',
    params: data
  })
}

// 绑定员工
export function wxBind(data) {
  return request({
    url: '/weixin-mp/oa/bind',
    method: 'post',
    data
  })
}

// 获取考核方案细则
export function getListByUserid(data) {
  return request({
    url: '/ljYearRules/getListByUserid',
    method: 'get',
    params: data
  })
}

// 提交考核方案
export function saveQuestions(data) {
  return request({
    url: '/ljYearRules/saveQuestions',
    method: 'post',
    data
  })
}

// 查询员工是否提交问卷
export function getQuestionStatus(data) {
  return request({
    url: '/questions/have',
    method: 'get',
    params: data
  })
}

// export function login(data) {
//   return request({
//     url: '/vue-element-admin/user/login',
//     method: 'post',
//     data
//   })
// }

// export function getInfo(token) {
//   return request({
//     url: '/vue-element-admin/user/info',
//     method: 'get',
//     params: { token }
//   })
// }

// export function logout() {
//   return request({
//     url: '/vue-element-admin/user/logout',
//     method: 'post'
//   })
// }
