import Vue from 'vue';
import App from './App';
import { router } from './router';
import axios from './config/http';
import store from './store';
import { Dialog } from 'vant';

Vue.use(Dialog);

Vue.prototype.$axios = axios;
Vue.prototype.$store = store

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
